import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  margin: 4px;
  background-color: #ffffff;
  color: #212121;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  flex: 1;
`;
