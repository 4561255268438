import type { MouseEventHandler } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { guess, newGame } from '../../reducers/game';
import { currentIndexSelector, currentSequenceSelector, scoreSelector } from '../../selectors/game';
import { useAppDispatch, useAppSelector } from '../../store';
import { Button } from '../button/Button';

const CardImage = styled.img`
  width: 220px;
  border-radius: 16px;
  box-shadow: var(--shadow-elevation-high);
  display: block;
`;

const MAX_INDEX = 51;

export const Game = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const currentIndex = useAppSelector(currentIndexSelector);
  const currentSequence = useAppSelector(currentSequenceSelector);
  const score = useAppSelector(scoreSelector);

  useEffect(() => {
    dispatch(newGame());
  }, []);

  const onClickAgain: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(newGame());
  };

  const onClickHigher: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(guess('higher'));
  };

  const onClickLower: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(guess('lower'));
  };

  const { [currentIndex]: currentCard } = currentSequence;

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#f2715a',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '56px 0 64px 0',
      }}
    >
      {currentCard === undefined ? null : <CardImage src={`/cards/${currentCard}.svg`} />}
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '32px',
        }}
      >
        {currentIndex === MAX_INDEX ? (
          <>
            <Button onClick={onClickAgain}>Again?</Button>
          </>
        ) : (
          <>
            <Button onClick={onClickHigher}>&#11014;&nbsp;HIGHER</Button>
            <div style={{ width: '28px' }} />
            <Button onClick={onClickLower}>&#11015;&nbsp;LOWER</Button>
          </>
        )}
      </div>
      <p>{currentIndex > 0 ? `${score}/${currentIndex}` : '\u00A0'}</p>
    </div>
  );
};
