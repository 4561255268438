import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import JSConfetti from 'js-confetti';
import { shuffle } from 'lodash';

import { cards } from '../data/cards';

const jsConfetti = new JSConfetti();

const INDEX_DIVISOR = 4;

const CONFETTI_NUMBER_FAIL = 2;
const CONFETTI_NUMBER_SUCCESS = 100;

const doConfetti = (card?: string): void => {
  const suits: Record<string, string> = {
    // C: '♣',
    // D: '♦',
    // H: '♥',
    // S: '♠',
    C: '♧',
    D: '♢',
    H: '♡',
    S: '♤',
  };

  const [, suit] = card?.split('') ?? [];

  const emojis = (suit === undefined ? ['☠️', '❌'] : ['✨', suits[suit]]) as string[];

  void jsConfetti.addConfetti({
    confettiNumber: suit === undefined ? CONFETTI_NUMBER_FAIL : CONFETTI_NUMBER_SUCCESS,
    emojis,
  });
};

type GameState = {
  currentIndex: number;
  currentSequence: string[];
  error: string | null;
  score: number;
};

const initialState: GameState = {
  currentIndex: 0,
  currentSequence: [],
  error: null,
  score: 0,
};

const gameSlice = createSlice({
  initialState,
  name: 'game',
  reducers: {
    guess(state, action: PayloadAction<'higher' | 'lower'>) {
      const { payload } = action;

      const { currentIndex, currentSequence } = state;

      const newIndex = currentIndex + 1;

      const { [currentIndex]: currentCard } = currentSequence;
      const { [newIndex]: nextCard } = currentSequence;

      if (currentCard === undefined || nextCard === undefined) {
        state.error = 'No card';

        return;
      }

      const currentCardIndex = Math.floor(cards.indexOf(currentCard) / INDEX_DIVISOR);
      const nextCardIndex = Math.floor(cards.indexOf(nextCard) / INDEX_DIVISOR);

      if (
        (payload === 'higher' && nextCardIndex >= currentCardIndex) ||
        (payload === 'lower' && nextCardIndex <= currentCardIndex)
      ) {
        state.error = null;
        state.score += 1;
        doConfetti(nextCard);
      } else {
        state.error = 'Wrong!';
        doConfetti();
      }

      state.currentIndex += 1;
    },
    newGame(state) {
      const currentSequence = shuffle(cards);

      state.currentIndex = 0;
      state.currentSequence = currentSequence;
      state.error = null;
      state.score = 0;
    },
  },
});

const { actions, reducer } = gameSlice;

export const { guess, newGame } = actions;

export default reducer;
