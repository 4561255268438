/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { RootState } from '../store';

export const currentIndexSelector = (state: RootState) => state.game.currentIndex;

export const currentSequenceSelector = (state: RootState) => state.game.currentSequence;

export const errorSelector = (state: RootState) => state.game.error;

export const scoreSelector = (state: RootState) => state.game.score;
